@import "styles/variables";
@import "styles/mixings";

.rateResortSearchContainer {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  scroll-margin: 20px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .titleBlock {
    display: flex;
    align-items: center;
    margin-right: 25px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 10px 0;
      width: 100%;
    }
  }

  .searchBlock {
    position: relative;
    flex: 1;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .badgeBlock {
    height: auto;
    width: 100%;
    text-align: center;

    img {
      width: 100%;
      margin-right: 26px;
      max-width: 63px;
    }

    @include media-breakpoint-down(md) {
      img {
        margin-right: 0;
      }

      &::before,
      &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        height: 1px;
        background: #c4c4c4;
        margin: 0 17px;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 22 * $px-base;
    line-height: 33 * $px-base;
    letter-spacing: 0.04em;
    color: $primary;
    margin: 0;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      font-size: 20 * $px-base;
      line-height: 30 * $px-base;
      text-align: center;
      letter-spacing: 0.04em;
    }
  }
}

.helpText {
  padding: 12px 20px;
}

.popUp {
  position: absolute;
  width: 100%;
  scrollbar-color: $secondary-light-blue transparent;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    border-radius: 5px;
    margin-right: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $secondary-light-blue;
    border-radius: 5px;
    height: 93px;
  }

  .container {
    margin-top: 10px;
    border-radius: 9px;
    opacity: 0.95;
    padding: 15px 0;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.23);
    border-radius: 9px;
    overflow: hidden;
    z-index: 1000;

    .resultsList {
      height: 15vh;
      overflow: auto;
      list-style-type: none;
      padding-left: 0;
      margin-right: 15px;

      & > li {
        font-size: 16px;
        line-height: 24px;
        padding: 7px 0 7px 20px;
        margin-right: 15px;
        cursor: pointer;

        &:hover {
          background: $ghost-white;
        }
      }
    }

    & > header {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0.5rem;
      padding-left: 20px;
    }
  }
}
