.fillBlack {
  svg {
    fill: #232323 !important;
  }
}

.fillGreen {
  svg {
    fill: #29903A !important;
  }
}

.fillRed {
  svg {
    fill: #FF0000 !important;
  }
}

.fillPrimary {
  svg {
    fill: #006FAC !important;
  }
}
