@import "variables";
// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

@mixin transparentBackgroundColorMixin($opacity: $opacity, $color: $color) {
    background-color: rgba(red($color), green($color), blue($color), $opacity);
}

// 2. Include any default variable overrides here

$text: $black;
$font-family-sans-serif: $font-family;
$font-size-base: $size-base; // 16px
$headings-font-weight: 700;

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
