@use "sass:math";

@use "sass:color";

$black: #3B3B3B;
$black-opacity: rgba(35, 35, 35, 0.7);
$light-gray: #F9F9F9;
$dark-gray: #6F777F;
$secondary-gray: #AAAAAA;
$border-gray: #EDEDED;
$white: #FFF;
$light-yellow: #E8FFA8;
$alice-blue: #f5f9fc;
$cerulean: #0c71b0;
$ghost-white: #f7f9fc;

/**
  Primary Blue
 */
$primary: #006FAC;
$primary-blue: $primary;

/**
  Primary Hover Blue
 */
$primary-hover: #00639F;
$battery-charged-blue: #11ABD8;

/**
  Primary Ocean Blue
 */
$primary-alt: #11ABD8;
$primary-ocean-blue: $primary-alt;

/**
  Secondary Deep Blue
 */
$secondary: #304769;
$secondary-deep-blue: $secondary;

/*
  Secondary Dark Blue
 */
$secondary-alt: #005889;
$secondary-dark-blue: $secondary-alt;

/*
  Secondary Light Blue
 */
$secondary-light-blue: #2CACD7;

/*
  Secondary Blue
 */
$secondary-blue: #576A85;

/*
  Background Light Blue
 */
$background-light-blue: #D8E6F1;

$red: #F25656;
$danger: $red;

$blue-gradient: linear-gradient(90deg, #0C70B0 0%, #11ABD8 100%);
$blue-gradient-opaque: linear-gradient(90deg, color.change(#0C70B0, $alpha: 0.4), 0%, color.change(#11ABD8, $alpha: 0.4) 100%);
$gray-gradient: linear-gradient(180deg, rgba(12, 113, 176, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
$white-gradient: linear-gradient(180deg, #F7F7F7 0%, rgba(255, 255, 255, 0) 100%);

$font-family: 'Helvetica Neue', 'Roboto', sans-serif;

$button-normal-bg: (
  "passive": $primary,
  "hover": $secondary-alt,
  "pressed": #04486C,
  "disabled": $light-gray
);

$button-light-bg: (
  "passive": $primary-alt,
  "hover": #1394BA,
  "pressed": #0B7595,
  "disabled": $light-gray
);

// $size-base: 1rem; // 16px
$size-base: 0.85rem; // 13.6px
$px-base: math.div($size-base, 16); // 1px

$statusPartOpen: #FF9E22;
$statusOpen: #3BA82D;
$statusClosed: #F17850;

$subtitleText: #444444;
$subtitleTextLight: #D1D1D1;


/*
    Variable of blur breakpoint
*/

$xxxl: 1901px;


/*
    Disable scroll-behavior
 */

$enable-smooth-scroll: false;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1326px,
);