@import "styles/variables";
@import "styles/mixings";

.searchPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  flex-direction: column;

  .list {
    display: flex;
    flex-direction: column;
  }

  .bg {
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.95;
    z-index: -1;
    position: absolute;
  }

  header {
    background: $primary;
    height: 75px;
    display: flex;
    align-items: center;

    .inner {
      display: flex;
      align-items: center;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }

      svg {
        width: 22px;
        height: 22px;
        fill: $white;
        margin-right: 20px;
        position: absolute;
        left: -30px;

        @include media-breakpoint-down(lg) {
          position: static;
          margin-right: 10px;
        }
      }

      .search {
        width: 100%;

        @include media-breakpoint-down(lg) {
          width: calc(100% - 22px);
        }

        input {
          width: 100%;
          background: inherit;
          outline: none;
          border: none;
          border-bottom: 1px solid #ffffff;
          font-weight: bold;
          font-size: 22 * $px-base;
          color: $white;
          padding: 5px 0;

          @include media-breakpoint-down(md) {
            font-size: 20 * $px-base;
          }

          &::placeholder {
            color: $white;
            opacity: 0.25;
          }
        }
      }
    }
  }

  .close {
    outline: none;
    background: inherit;
    border: none;
    position: absolute;
    right: 40px;
    top: 18px;
    width: 12px;
    height: 12px;

    @include media-breakpoint-down(md) {
      right: 15px;
    }

    &:hover,
    &:focus {
      opacity: 0.5;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 5px;
      content: " ";
      height: 13px;
      width: 3px;
      background-color: $white;
      border: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .resultSearch {
    flex: 1;
    padding-bottom: env(safe-area-inset-bottom);
    overflow: auto;
    text-align: left;
    font-size: 20 * $px-base;
    font-weight: 400;

    .helpText {
      font-weight: 500;
      font-size: 20 * $px-base;
      text-align: center;
      color: $black;
      margin-top: 75px;

      @include media-breakpoint-down(md) {
        padding: 0 15px;
        margin-top: 50px;
      }
    }

    .columns {
      display: flex;
      justify-content: space-between;
      margin-top: 19px;
      margin-left: -7px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0 15px;
      }

      &.isLoading {
        justify-content: center;
        padding-top: 75px;

        >span {
          display: block;
          margin: 0 auto;
        }
      }

      .category {
        width: 47%;

        &.categoryEmpty {
          @include media-breakpoint-down(md) {
            order: 2;
          }
        }

        &.loading>span {
          display: block;
          margin: 0 auto;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 26px;
        }

        h2 {
          font-weight: bold;
          font-size: 20 * $px-base;
          line-height: 140.62%;
          color: $black;
          padding-left: 7px;
          margin-bottom: 8px;

          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }

        .item {
          font-size: 18 * $px-base;
          line-height: 140.62%;
          color: $black;
          padding: 10px 7px 10px 7px;
          word-break: break-word;
          display: inline-flex;
          flex-wrap: wrap;

          &:hover {
            background: $primary;
            border-radius: 8px;
            color: $white;
            text-decoration: none;

            >* {
              color: $white;

              &.tag {
                color: $white;
                background: $primary;
                border: solid $white 1px;
              }
            }
          }

          .tag {
            color: $white;
            font-size: 14 * $px-base;
            background: #8090a0;
            border-radius: 5px;
            padding: 2px 8px;
            margin: 0 4px 0 4px;
            white-space: nowrap;
            border: solid transparent 1px;
            text-decoration: none;

            @include media-breakpoint-down(md) {
              padding: 0 4px;
            }
          }

          >* {
            font-size: 18 * $px-base;
            line-height: 140.62%;
            color: $black;
            display: flex;
            align-items: center;

            &:first-child {
              margin-right: 20px;
            }
          }

          .link {
            color: $primary-alt;
          }

          .linkBlock {
            margin: 0 0.2rem;

            &::before {
              content: "( ";
            }

            &::after {
              content: " )";
            }
          }

          &.noResult {
            opacity: 0.5;

            &:hover {
              background: inherit;
              color: $black;
            }
          }
        }
        .focused { 
          background: $border-gray;
          border-radius: 8px;
        }
      }
    }
  }
}