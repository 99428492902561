@import "styles/variables";
@import "styles/mixings";

.link {
  color: $primary-ocean-blue;
  display: inline-flex;
  align-items: center;
  font-weight: 400;

  svg {
    height: 6px;
    margin-left: 6px;
  }
}